import React, { useState, useEffect, useRef } from "react";

import { motion, useAnimate, useInView, useSpring } from "framer-motion";

import { useRafLoop } from "react-use";
import { useWindowSize } from "@react-hook/window-size";

const MarqueeItem = ({ marqueeContent, speed, windowWidth }) => {
  const [repeatContent, setRepeatContent] = useState(0);
  const [lastWidth, setLastWidth] = useState(0);
  const itemRef = useRef();
  const itemSizeRef = useRef();

  const [width, height] = useWindowSize();

  const x = useRef(0);

  const setX = () => {
    if (!itemRef.current || !itemSizeRef.current) {
      return;
    }
    const xPercentage = (x.current / itemSizeRef.current.width) * 100;
    if (xPercentage < -100) {
      x.current = 0;
    }
    if (xPercentage > 0) {
      x.current = -itemSizeRef.current.width;
    }
    itemRef.current.style.transform = `translate3d(${xPercentage}%, 0, 0)`;
  };

  useEffect(() => {
    if (itemRef.current && windowWidth > lastWidth) {
      const boundingClient = itemRef.current.getBoundingClientRect();
      if (boundingClient?.width > 0) {
        setLastWidth(windowWidth);
        const repeat = Math.ceil(
          boundingClient?.width > 0 ? windowWidth / boundingClient.width : 1
        );
        setRepeatContent(repeat > 1 ? repeat : 1);
        itemSizeRef.current = itemRef.current.getBoundingClientRect();
      }
    }
  }, [windowWidth, itemRef.current]);

  const loop = () => {
    x.current -= speed.get();
    setX();
  };

  const [_, loopStart] = useRafLoop(loop, false);

  useEffect(() => {
    if (repeatContent > 0) loopStart();
  }, [repeatContent]);

  return (
    <>
      <div className="marquee-item" ref={itemRef}>
        {Array(repeatContent > 0 ? repeatContent : 1)
          .fill(undefined)
          .map((element, index) => (
            <div key={index} className="item">
              {marqueeContent}
            </div>
          ))}
      </div>
    </>
  );
};

const Marquee = ({ marqueeContent }) => {
  const [width, height] = useWindowSize();

  const speedSpring = useSpring(width > 768 ? 0.1 : 0.2, {
    damping: 40,
    stiffness: 90,
    mass: 5,
  });

  // const loop = () => {
  //   // speedSpring.set(speed + 1);
  // };
  // useRafLoop(loop);

  return (
    <>
      <div className="marquee">
        <MarqueeItem
          marqueeContent={marqueeContent}
          speed={speedSpring}
          windowWidth={width}
        />
        <MarqueeItem
          marqueeContent={marqueeContent}
          speed={speedSpring}
          windowWidth={width}
        />
      </div>
    </>
  );
};

export default Marquee;
