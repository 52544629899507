import React, { useState, useEffect } from "react";

const Beer = () => {
  return (
    <>
      <div className="content-box beer">
        <div className="header">
          <div className="title">Hold my Bear</div>
          {/* <div className="text">Hold my Bear..</div> */}
        </div>

        <div className="content">
          <div className="image-box">
            <div className="image">
              <img src="images/beer.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Beer;
