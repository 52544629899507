import React, { useState, useEffect } from "react";

import MainPage from "./MainPage";

const Home = ({ fontsLoaded }) => {
  return (
    <>
      <MainPage fontsLoaded={fontsLoaded} />
    </>
  );
};

export default Home;
