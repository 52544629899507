import React, { useState, useEffect } from "react";

import Header from "./Header";
import Network from "./Network";
import Tokenomics from "./Tokenomics";
import Socials from "./Socials";
import Airdrop from "./Airdrop";
import Liquidity from "./Liquidity";
import NFTs from "./NFTs";
import Beer from "./Beer";

const MainPage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const checkFontLoad = async () => {
      await document.fonts.ready;
      setFontsLoaded(true);
      console.log("setFontsLoaded");
    };
    checkFontLoad();
  }, []);

  return (
    <>
      {(!fontsLoaded || !imagesLoaded) && <div className="main-loading"></div>}
      <div className="main-page">
        <Header setImagesLoaded={setImagesLoaded} isLoaded={fontsLoaded && imagesLoaded} />
        <Network />
        <Liquidity />
        <Tokenomics />
        <NFTs />
        <Beer />
        <Socials />
      </div>
    </>
  );
};

export default MainPage;
