import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Liquidity = () => {
  return (
    <>
      <div className="content-box liquidity other">
        <div className="header">
          <div className="title">BEAR Pool</div>

          <div className="text">$BEAR liquidity is in the hands of the community.</div>
        </div>

        <div className="content">
          <div className="image-box">
            <div className="image">
              <img src="images/bear-pool.jpeg" alt="" />
            </div>
          </div>
          <div className="stats">
            <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://orchestra.finance/pools/XRP-4245415200000000000000000000000000000000.rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW"
                target="_blank"
                rel="noopener noreferrer"
              >
                Join $BEAR Pool
              </Link>
            </motion.button>
            {/* <div className="stat">
              <div className="label">Total Supply</div>
              <div className="value">589,000,000 $BEAR</div>
            </div> */}
            <div className="text">Join us and throw some $BEAR into the liquidity pool!</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Liquidity;
